import http from "../../http";

const profit = {

    saveProfitUser(r) {
        return http.spin.payload("/gateway/api/merchant/profitUser/v1/saveProfitUser", r)
    },
    selectUserInfo(r) {
        return http.spin.get("/gateway/api/merchant/profitUser/v1/selectUserInfo", r)
    },
    deleteUserInfo(r) {
        return http.confirm.tip.post("/gateway/api/merchant/profitUser/v1/deleteUserInfo", r)
    },
    changeUserInfo(r) {
        return http.spin.payload("/gateway/api/merchant/profitUser/v1/changeUserInfo", r)
    },
    selectProfitUserPage(r) {
        return http.spin.get("/gateway/api/merchant/profitUser/v1/selectProfitUserPage", r)
    },
    openUserList(r) {
        return http.spin.post("/gateway/api/merchant/profitUser/v1/openUserList", r)
    },
    removeUserList(r) {
        return http.spin.post("/gateway/api/merchant/profitUser/v1/removeUserList", r)
    },
    selectMerchantOpenUser(r) {
        return http.spin.get("/gateway/api/merchant/profitUser/v1/selectMerchantOpenUser", r)
    },
    assignProfit(r) {
        return http.spin.payload("/gateway/api/merchant/profitOrder/v1/assignProfit", r)
    },
    selectOrderPage(r) {
        return http.spin.get("/gateway/api/merchant/profitOrder/v1/selectOrderPage", r)
    },
    submitProfitTask(r) {
        return http.confirm.tip.post("/gateway/api/merchant/profitOrder/v1/submitProfitTask", r)
    },
    selectOrderInfo(r) {
        return http.spin.get("/gateway/api/merchant/profitOrder/v1/selectOrderInfo", r)
    },
    referTask(r) {
        return http.spin.get("/gateway/api/merchant/profitOrder/v1/getProfitRes", r)
    },
    unfreezeOrder(r) {
        return http.confirm.tip.payload("/gateway/api/merchant/profitOrder/v1/unfreezeOrder", r)
    },
}
export default profit
