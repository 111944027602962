import http from "../../http";

const information = {
    getUserLoginLogPage(param) {
        return http.spin.get("/gateway/api/lfuser/loginLog/selectLogPage", param)
    },
    getUserLoginLogInfo(param) {
        return http.spin.get("/gateway/api/lfuser/loginLog/selectById", param)
    },
    selectBackLogList(param) {
        return http.spin.get("/gateway/api/lfuser/loginLog/selectBackList", param)
    },
    downBackLogFile(param) {
        return http.confirm.tip.get("/gateway/api/lfuser/loginLog/downBackFile", param)
    },
}
export default information

