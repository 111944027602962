import http from "../../http";

const gather = {
    selectGatherByPage(param) {
        return http.spin.get("/gateway/syinfopublish/api/pc/information/tenant/selectByPage", param)
    },
    generateGather(param) {
        return http.spin.get("/gateway/syinfopublish/api/pc/information/tenant/generateGather", param)
    },

}
export default gather

