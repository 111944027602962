import http from "../../http";

const dbtable = {
    selectByPage(param){
        return http.get("/gateway/api/lfcommon/api/dbTable/selectByPage", param);
    },
    getDbNameList(){
        return http.get("/gateway/api/lfcommon/api/dbTable/getDbNameList");
    },
    getTbNameList(){
        return http.get("/gateway/api/lfcommon/api/dbTable/getTbNameList");
    },
}
export default dbtable