import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  //定义路由
  {
    path: "/",
    name: "/",
    component: () => import("@/views/portal/index.vue"),
  },
  //定义路由
  {
    path: "/iframe",
    name: "/iframe",
    component: () => import("@/views/iframe.vue"),
  },
  {
    path: "/portal",
    name: "/portal",
    component: () => import("@/views/portal/index.vue"),
  },
  {
    path: "/authCloudSystem",
    name: "/authCloudSystem",
    component: () => import("@/views/cloud/system/index.vue"),
  },
  {
    path: "/warrantsystem",
    name: "/warrantsystem",
    component: () => import("@/views/cloud/system/warrant.vue"),
  },
  {
    path: "/tenantSystemStaff",
    name: "/tenantSystemStaff",
    component: () => import("@/views/cloud/system/staff.vue"),
  },
  {
    path: "/orgRole",
    name: "/orgRole",
    component: () => import("@/views/cloud/org/role/index.vue"),
  },
  {
    path: "/orgStaff",
    name: "/orgStaff",
    component: () => import("@/views/cloud/org/staff/index.vue"),
  },
  {
    path: "/authCloudMenu",
    name: "/authCloudMenu",
    component: () => import("@/views/cloud/menu/index.vue"),
  },
  {
    path: "/columnconfigindex",
    name: "/columnconfigindex",
    component: () => import("@/views/cloud/column/index.vue"),
  },
  {
    path: "/columnconfigtreeindex",
    name: "/columnconfigtreeindex",
    component: () => import("@/views/cloud/column/treeindex.vue"),
  },
  {
    path: "/authCloudFunction",
    name: "/authCloudFunction",
    component: () => import("@/views/cloud/function/index.vue"),
  },
  {
    path: "/authCloudRole",
    name: "/authCloudRole",
    component: () => import("@/views/cloud/role/index.vue"),
  },
  {
    path: "/authCloudTenant",
    name: "/authCloudTenant",
    component: () => import("@/views/cloud/tenant/index.vue"),
  },
  {
    path: "/warrantsystem",
    name: "/warrantsystem",
    component: () => import("@/views/cloud/system/warrant.vue"),
  },
  {
    path: "/authCloudSonSystem",
    name: "/authCloudSonSystem",
    component: () => import("@/views/cloud/system/sub/index.vue"),
  },
  {
    path: "/tenantsyswarrantorg",
    name: "/tenantsyswarrantorg",
    component: () => import("@/views/cloud/system/warrantorg.vue"),
  },
  {
    path: "/tenantsyswarrantaddorg",
    name: "/tenantsyswarrantaddorg",
    component: () => import("@/views/cloud/system/addorg.vue"),
  },
  {
    path: "/tenantstaff",
    name: "/tenantstaff",
    component: () => import("@/views/cloud/tenant/childrens/staff.vue"),
  },
  {
    path: "/tenantrole",
    name: "/tenantrole",
    component: () => import("@/views/cloud/tenant/childrens/role.vue"),
  },
  {
    path: "/actuatormanage",
    name: "/actuatormanage",
    component: () => import("@/views/message/actuatormanage.vue"),
  },
  {
    path: "/messagebatchlist",
    name: "/messagebatchlist",
    component: () => import("@/views/message/batchlist.vue"),
  },
  {
    path: "/messagebatchdetail",
    name: "/messagebatchdetail",
    component: () => import("@/views/message/batchdetail.vue"),
  },
  {
    path: "/desensitization",
    name: "/desensitization",
    component: () => import("@/views/auth/desensitization.vue"),
  },
  {
    path: "/userlogoff",
    name: "/userlogoff",
    component: () => import("@/views/auth/userlogoff.vue"),
  },
  {
    path: "/oemdimension",
    name: "/oemdimension",
    component: () => import("@/views/cloud/tenant/dimension/index.vue"),
  },
  {
    path: "/requesterrorlog",
    name: "/requesterrorlog",
    component: () => import("@/views/sys/request/errorlog.vue"),
  },
  {
    path: "/dimensionindex",
    name: "/dimensionindex",
    component: () => import("@/views/cloud/dimension/index.vue"),
  },
  {
    path: "/dbbackindex",
    name: "/dbbackindex",
    component: () => import("@/views/cloud/dbback/index.vue"),
  },
  {
    path: "/tbtableindex",
    name: "/tbtableindex",
    component: () => import("@/views/cloud/dbtable/index.vue"),
  },
  {
    path: "/tenantinfoindex",
    name: "/tenantinfoindex",
    component: () => import("@/views/suiyue/information/tenantinfo.vue"),
  },
  {
    path: "/servicetipsindex",
    name: "/servicetipsindex",
    component: () => import("@/views/manager/servicetips/index.vue"),
  },
  {
    path: "/userLoginLog",
    name: "/userLoginLog",
    component: () => import("@/views/cloud/user/userLoginLog.vue"),
  },
  {
    path: "/infoPushLogIndex",
    name: "/infoPushLogIndex",
    component: () => import("@/views/suiyue/information/pushlog/index.vue"),
  },
  {
    path: "/merchantNotifyIndex",
    name: "/merchantNotifyIndex",
    component: () => import("@/views/merchant/notify/Index.vue"),
  },
  {
    path: "/merchantPayNotifyIndex",
    name: "/merchantPayNotifyIndex",
    component: () => import("@/views/merchant/notify/PayIndex.vue"),
  },

  {
    path: "/merchantApplyIndex",
    name: "/merchantApplyIndex",
    component: () => import("@/views/merchant/apply/Index.vue"),
  },
  {
    path: "/informationContract",
    name: "/informationContract",
    component: () => import("@/views/suiyue/information/contract/Index.vue"),
  },
  {
    path: "/informationContractSet",
    name: "/informationContractSet",
    component: () => import("@/views/suiyue/information/contract/Setting.vue"),
  },
  {
    path: "/merchantTraceIndex",
    name: "/merchantTraceIndex",
    component: () => import("@/views/merchant/trace/Index.vue"),
  },
  {
    path: "/infoGatherIndex",
    name: "/infoGatherIndex",
    component: () => import("@/views/suiyue/information/gather/Index.vue"),
  },
  {
    path: "/merchantProfitUserIndex",
    name: "/merchantProfitUserIndex",
    component: () => import("@/views/merchant/profit/UserIndex.vue"),
  },
  {
    path: "/merchantProfitOrderIndex",
    name: "/merchantProfitOrderIndex",
    component: () => import("@/views/merchant/apply/profit/Index.vue"),
  },
  {
    path: "/merchantInvokeIndex",
    name: "/merchantInvokeIndex",
    component: () => import("@/views/merchant/invoke/Index.vue"),
  },
  {
    path: "/merchantOrderProfitIndex",
    name: "/merchantOrderProfitIndex",
    component: () => import("@/views/merchant/apply/profit/OrderIndex.vue"),
  },
  {
    path: "/msgBatchIndex",
    name: "/msgBatchIndex",
    component: () => import("@/views/message/batch/Index.vue"),
  },
  {
    path: "/msgProcessorIndex",
    name: "/msgProcessorIndex",
    component: () => import("@/views/message/processor/Index.vue"),
  },
  {
    path: "/merchantIndex",
    name: "/merchantIndex",
    component: () => import("@/views/merchant/merchant/Index.vue"),
  },
  {
    path: "/messageResIndex",
    name: "/messageResIndex",
    component: () => import("@/views/message/res/Index.vue"),
  },
  {
    path: "/messageTaskIndex",
    name: "/messageTaskIndex",
    component: () => import("@/views/message/task/Index.vue"),
  },
  {
    path: "/messageTemplateIndex",
    name: "/messageTemplateIndex",
    component: () => import("@/views/message/template/Index.vue"),
  },
  {
    path: "/messageHomePage",
    name: "/messageHomePage",
    component: () => import("@/views/message/batch/HomePage.vue"),
  },
  {
    path: "/attendanceIndex",
    name: "/attendanceIndex",
    component: () => import("@/views/cloud/workflow/oa/AtendanceIndex.vue"),
  },
  {
    path: "/attendanceDeviceIndex",
    name: "/attendanceDeviceIndex",
    component: () => import("@/views/cloud/workflow/oa/DeviceIndex.vue"),
  },
  {
    path: "/biBaseIndex",
    name: "/biBaseIndex",
    component: () => import("@/views/bi/base/Index.vue"),
  },
  {
    path: "/monitorTemplateIndex",
    name: "/monitorTemplateIndex",
    component: () => import("@/views/monitor/template/Index.vue"),
  },
  {
    path: "/monitorMsgIndex",
    name: "/monitorMsgIndex",
    component: () => import("@/views/monitor/msg/Index.vue"),
  },
  {
    path: "/merchantProviderIndex",
    name: "/merchantProviderIndex",
    component: () => import("@/views/merchant/provider/Index.vue"),
  },
  {
    path: "/messageQrViewIndex",
    name: "/messageQrViewIndex",
    component: () => import("@/views/message/qrmanage.vue"),
  },
  {
    path: "/messageBatchInfo",
    name: "/messageBatchInfo",
    component: () => import("@/views/message/batch/businessinfo.vue"),
  },
  {
    path: "/userLabelPush",
    name: "/userLabelPush",
    component: () => import("@/views/baseuser/userlabel/Index.vue"),
  },
  {
    path: "/messageBusinessIndex",
    name: "/messageBusinessIndex",
    component: () => import("@/views/message/business/Index.vue"),
  },
  {
    path: "/merchantOrderInfoIndex",
    name: "/merchantOrderInfoIndex",
    component: () => import("@/views/merchant/apply/OrderInfo.vue"),
  },
];

// 创建路由对象
const router = createRouter({
  //hash模式的路由
  history: createWebHashHistory(),
  routes,
});

//导航前守卫(跳转前)
router.beforeEach(function (to, from, next) {
  next();
});

export default router;
