import http from "../../http";

const org = {
    searchMatch(r) {
        return  http.get("/gateway/org/pc/organization/searchMatch",r )
    },
    selectChildByOrgCode(r) {
        return  http.get("/gateway/org/pc/organization/selectChildByOrgCode",r )
    },
}
export default org
