import http from "../../http";

const userLabel = {
    selectUserLabelPage(param) {
        return http.spin.get("/gateway/api/lfuser/userLabel/selectUserLabelPage", param)
    },
    saveUserLabelConfig(param) {
        return http.confirm.payload("/gateway/api/lfuser/userLabel/saveUserLabelConfig", param)
    },
    queryUserLabelConfig(param) {
        return http.spin.get("/gateway/api/lfuser/userLabel/queryUserLabelConfig", param)
    },
    countUserLabelByCode(param) {
        return http.spin.get("/gateway/api/lfuser/userLabel/countUserLabelByCode", param)
    },
    deleteUserLabelConfig(param) {
        return http.confirm.tip.post("/gateway/api/lfuser/userLabel/deleteUserLabelConfig", param)
    },
}
export default userLabel

