import http from "../../http";

const res = {
    selectCustomerHas(r) {
        return  http.spin.get("/gateway/api/v2/message/res/selectCustomerHas",r )
    },
    selectResPage(r) {
        return  http.spin.get("/gateway/api/v2/message/res/selectResPage",r )
    },

}
export default res
