import http from "../../http";

const servicetips = {
    searchPage(r) {
        return  http.get("/gateway/api/symanage/pc/serviceTips/searchPage",r )
    },
    addTips(r) {
        return  http.tip.payload("/gateway/api/symanage/pc/serviceTips/addTips",r )
    },
    updateTips(r) {
        return  http.tip.payload("/gateway/api/symanage/pc/serviceTips/updateTips",r )
    },
    deleteTips(r) {
        return  http.confirm.tip.post("/gateway/api/symanage/pc/serviceTips/deleteTips",r )
    },
    queryTipsInfo(r) {
        return  http.get("/gateway/api/symanage/pc/serviceTips/queryTipsInfo",r )
    },
}
export default servicetips
