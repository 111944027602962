const modulesApi = require.context('./modules', true, /\.js$/)

const map = new Map()
const api = new Object()
modulesApi.keys().forEach(key => {
    const newKey = key.replace(/(\.\/|\.js)/g, '')
    let name = newKey.replace('/', '.');
    map.set(name, require(`./modules/${newKey}`))
    let split = name.split('.');
    let temp = api
    for (let i = 0; i < split.length; i++) {
        let keyName = split[i];
        if (i < split.length - 1) {
            if (!api[keyName]) {
                temp[keyName] = {}
            }
            temp = temp[keyName]
        }

        if (i == split.length - 1) {
            // 最后一个元素
            temp[keyName] = require(`./modules/${newKey}`).default
        }
    }
})
console.log(api)
export default api
