import http from "../../http";

const operateLog = {
    selectOperateLogList(param){
        return http.get("/gateway/api/ck/SystemOperateLog/selectOperateLogList", param);
    },
    getOperateLogIndex(param){
        return http.get("/gateway/api/ck/SystemOperateLog/getOperateLogIndex", param);
    },
    getHomePageLine(param){
        return http.get("/gateway/api/ck/SystemOperateLog/getHomePageLine", param);
    },
    getHomePageApp(param){
        return http.get("/gateway/api/ck/SystemOperateLog/getHomePageApp", param);
    },
    getHomePageIndex(param){
        return http.get("/gateway/api/ck/SystemOperateLog/getHomePageIndex", param);
    },
    getTableMonth(param){
        return http.get("/gateway/api/ck/SystemOperateLog/getTableMonth", param);
    },
    getDatesByMonth(param){
        return http.get("/gateway/api/ck/SystemOperateLog/getDatesByMonth", param);
    },
}
export default operateLog