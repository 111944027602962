import http from "../../http";

const refund = {

    unifiedRefund(r) {
        return  http.spin.payload("/gateway/api/merchant/refund/v1/unifiedRefund",r )
    },
    selectApplyNo(r) {
        return  http.spin.get("/gateway/api/merchant/refund/v1/selectApplyNo",r )
    },
}
export default refund
