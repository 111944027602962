import http from "../../http";

const company = {

    saveCompany(r) {
        return http.spin.payload("/gateway/api/merchant/company/v1/saveCompany", r)
    },
    change(r) {
        return http.tip.post("/gateway/api/merchant/company/v1/change", r)
    },
    delete(r) {
        return http.confirm.tip.post("/gateway/api/merchant/company/v1/delete", r)
    },
    selectCompanyList(r) {
        return http.spin.get("/gateway/api/merchant/company/v1/selectCompanyList", r)
    },

}
export default company
