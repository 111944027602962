import Vue, {createApp} from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
import store from './store'
import md5 from 'js-md5';
import './styles/index.less'
import core from "@/utils/core";
import {get, post} from "@/api";
import VueCropper from 'vue-cropper'
import jQueryPlugin from './utils/jq'
import api from './api/api'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import directive from "@/directive/index.js";
import { message } from "@/components/Message";
import http from '@/api/http'
import {Spin} from 'view-ui-plus'
import JsonViewer from 'vue-json-viewer'
const app = createApp(App)　

app.use(store)
    .use(router)
    .use(VueCropper)
    .use(jQueryPlugin)
    .use(ViewUIPlus)
    .use(JsonViewer)
    .mount('#app')
directive(app)
app.config.globalProperties.$core = core;
app.config.globalProperties.$get = get;
app.config.globalProperties.$post = post;
app.config.globalProperties.$md5 = md5;
app.config.globalProperties.$api = api;
app.config.globalProperties.$http = http;
app.config.globalProperties.$Spin = Spin;
app.config.globalProperties.$message = message;