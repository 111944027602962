/**** request.js ****/
// 导入axios
import axios from 'axios'
import proxy from "@/api/proxy.js";
import router from "@/router";

const service = axios.create({})

// 创建取消请求
const CancelToken = axios.CancelToken
const source = CancelToken.source()
var cancelList = []
var removePending = () => {
    if (cancelList && cancelList.length) {
        cancelList.map(item => {
            if (item.source) {
                item.source.cancel()
            }
        })
    }
}

service.interceptors.request.use(
    (config) => {
        if (process.env.VUE_APP_FLAG != "test") {
            var target = config.url.substring(0, config.url.indexOf("/", 1));
            if (target.indexOf("/") == 0 && target != null && target != "") {
                var domain = proxy[target];
                domain = domain != null ? domain.target : null;
                if (domain != null) config.url = domain + config.url.substring(config.url.indexOf("/", 1));
            }
        }
        // 判断是否是获取验证码接口，是就让他跑，不是就标记为取消
        if (config.url.indexOf('captchaImage') == -1) {
            config.cancelToken = source.token
            cancelList.push(config.cancelToken)
        }

        let token = window.sessionStorage.getItem("accessToken");
        if (token != null) {
            config.headers.Authorization = token;
        }
        //console.log("------------"+openId+"------------"+token);
        if (config.method == "get") config.data = true;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


service.interceptors.response.use(response => {
    // 拦截响应，统一处理
    try {
        if (response && response.data && response.data.code) {
            let code = response.data.code;

            switch (response.data.code) {
                // 如果是登录过期或者是被人踢下线
                case 600:
                case 601:
                case 602:
                case "600":
                case "601":
                case "602":
                    window.sessionStorage.removeItem("custGlobalId");
                    window.sessionStorage.removeItem("accessToken");
                    window.sessionStorage.removeItem("openId");
                    window.sessionStorage.removeItem("userInfo");
                    window.sessionStorage.removeItem("systemList");
                    window.sessionStorage.removeItem("userDetail");
                    let url = window.location.href;
                    let link = url.substring(url.lastIndexOf("/"));
                    var loginInfo = {loginInfo: {login: false, right: false, msg: "登录过期，请重新登录！"}, link: link};
                    // 先中断除了获取验证码的请求
                    removePending();
                    let href = window.location.protocol + "//" + window.location.host;
                    console.log(href)
                    try {
                        if (window.vue && window.vue.portalCheckRight) {
                            window.vue.portalCheckRight({
                                login: false,
                                right: false,
                                msg: "登录过期，请重新登录！"
                            }, link);
                        } else {
                            top.location = href
                            // window.location.href = href
                            return false;
                        }
                    } catch (e) {
                        top.location = href
                        // window.location.href = href
                        return false;
                    }
                    return response
            }
            return response
        } else {
            return response
        }
    } catch (err) {
        return Promise.reject(err)
    }

})

export default service