import http from "../../http";

const monitor = {
  selectTemplateByPage(param) {
    return http.spin.get(
      "/gateway/api/lfcommon/pc/monitor/selectTemplateByPage",
      param
    );
  },
  selectAllListOption() {
    return http.spin.get(
      "/gateway/api/lfcommon/pc/monitor/selectAllListOption"
    );
  },
  addTemplate(param) {
    return http.spin.payload(
      "/gateway/api/lfcommon/pc/monitor/addTemplate",
      param
    );
  },
  selectTemplateById(param) {
    return http.spin.get(
      "/gateway/api/lfcommon/pc/monitor/selectTemplateById",
      param
    );
  },
  updateTemplate(param) {
    return http.spin.payload(
      "/gateway/api/lfcommon/pc/monitor/updateTemplate",
      param
    );
  },
  deleteTemplate(param) {
    return http.confirm.tip.post(
      "/gateway/api/lfcommon/pc/monitor/deleteTemplate",
      param
    );
  },
  queryMonitorByPage(param) {
    return http.spin.get(
      "/gateway/api/lfcommon/pc/monitor/queryMonitorByPage",
      param
    );
  },
  selectMonitorById(param) {
    return http.spin.get(
      "/gateway/api/lfcommon/pc/monitor/selectMonitorById",
      param
    );
  },
  handleMonitor(param) {
    return http.spin.payload(
      "/gateway/api/lfcommon/pc/monitor/handleMonitor",
      param
    );
  },
  batchHandleMonitor(param) {
    return http.spin.payload(
      "/gateway/api/lfcommon/pc/monitor/batchHandleMonitor",
      param
    );
  },
  deleteMsg(param) {
    return http.confirm.tip.post(
      "/gateway/api/lfcommon/pc/monitor/deleteMsg",
      param
    );
  },
};
export default monitor;
