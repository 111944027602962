/**** http.js ****/
import send from './send';
import {Modal} from 'view-ui-plus';

const http = {
    confirm: {
        async get(url, params) {
            let result = await this.confirmRequest();
            if (result) {
                return send.get(url, params)
            }
        },
        async post(url, params) {
            let result = await this.confirmRequest();
            if (result) {
                return send.post(url, params)
            }
        },
        async payload(url, params) {
            let result = await this.confirmRequest();
            if (result) {
                return send.payload(url, params)
            }
        },
        confirmRequest() {
            return new Promise((resolve) => {
                Modal.confirm({
                    title: '是否确认继续进行该操作?',
                    onOk: () => {
                        resolve(true)
                    },
                    onCancel: () => {
                        resolve(false);
                    }
                })
            });
        },
        tip: {
            async get(url, params) {
                let result = await this.confirmRequest();
                if (result) {
                    return send.get(url, params, true);
                }
            },
            async post(url, params) {
                let result = await this.confirmRequest();
                if (result) {
                    return send.post(url, params, true);
                }
            },
            async payload(url, params) {
                let result = await this.confirmRequest();
                if (result) {
                    return send.payload(url, params, true);
                }
            },
            confirmRequest() {
                return new Promise((resolve) => {
                    Modal.confirm({
                        title: '是否确认继续进行该操作?',
                        onOk: () => {
                            resolve(true)
                        },
                        onCancel: () => {
                            resolve(false);
                        }
                    })
                });
            }
        },
    },
    tip: {
        get(url, params) {
            return send.get(url, params, true);
        },
        post(url, params) {
            return send.post(url, params, true);

        },
        payload(url, params) {
            return send.payload(url, params, true);
        },
    },
    get(url, params, spin) {
        return send.get(url, params, spin);
    },
    post(url, params) {
        return send.post(url, params);

    },
    payload(url, params) {
        return send.payload(url, params);
    },
    spin:{
        tip: {
            get(url, params) {
                return send.get(url, params, true,true);
            },
            post(url, params) {
                return send.post(url, params, true,true);

            },
            payload(url, params) {
                return send.payload(url, params, true,true);
            },
        },
        get(url, params, spin) {
            return send.get(url, params, false,true);
        },
        post(url, params) {
            return send.post(url, params,false,true);

        },
        payload(url, params) {
            return send.payload(url, params,false,true);
        },
    }
}
export default http