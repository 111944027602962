import http from "../../http";

const apply = {

    settle(r) {
        return http.confirm.post("/gateway/api/merchant/payApply/v1/settle", r)
    },

    selectInfo(r) {
        return http.spin.get("/gateway/api/merchant/payApply/v1/selectInfo", r)
    },
    refreshOrderAmount(r) {
        return http.spin.get("/gateway/api/merchant/pay/v1/refreshOrderAmount", r)
    },
    selectOrderInfoPage(r) {
        return http.spin.get("/gateway/api/merchant/orderInfo/v1/selectOrderInfoPage", r)
    },
}
export default apply
