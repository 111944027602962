import http from "../../http";

const db = {
    getDbIndex(param){
        return http.get("/gateway/api/lfcommon/api/db/searchPage", param);
    },
    getDbNameList(){
        return http.get("/gateway/api/lfcommon/api/db/selectDbName");
    },
    download(id){
        return http.post("/gateway/api/lfcommon/api/db/download",{id});
    },
}
export default db