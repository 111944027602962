import http from "../../http";

const template = {
    selectByPage(r) {
        return http.spin.get("/gateway/api/v2/message/template/selectByPage", r)
    },
    selectByList(r) {
        return http.spin.get("/gateway/api/v2/message/template/selectByList", r)
    },
    saveTemplate(r) {
        return http.spin.payload("/gateway/api/v2/message/template/saveTemplate", r)
    },
    selectById(r) {
        return http.spin.get("/gateway/api/v2/message/template/selectById", r)
    },
    change(r) {
        return http.confirm.tip.post("/gateway/api/v2/message/template/change", r)
    },
    delete(r) {
        return http.confirm.tip.post("/gateway/api/v2/message/template/delete", r)
    },

}
export default template
