var domain = function (param) {
  if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'testbuild' || process.env.NODE_ENV == 'test') {
    return param.dev;
  }
  if (process.env.NODE_ENV == 'dev') {
    return param.dev;
  }
  if (process.env.NODE_ENV == 'production') {
    return param.prod;
  }
}

module.exports = {
  "/gateway": {
    target: domain({
      dev: "http://liefeng-gateway.test.gongxiangplat.com",
      test: "http://liefeng-gateway.test.gongxiangplat.com",
      prod: "https://api.gateway.prod.liefengtech.com",
    }),
    ws: false,
    changeOrigin: true,
    secure: true,
  }
}