import http from "../../http";

const information = {
    getTenantList(param) {
        return http.spin.get("/gateway/syinfopublish/api/pc/information/v2/searchTenantPage", param)
    },
    selectHasOrgCode() {
        return http.get("/gateway/syinfopublish/api/pc/information/v2/selectHasOrgCode", {})
    },
    selectHasColumnCode() {
        return http.get("/gateway/syinfopublish/api/pc/information/v2/selectHasColumnCode", {})
    },
    getBaseInfo(param) {
        return http.get("/gateway/syinfopublish/api/pc/information/v2/queryById", param)
    },
    saveInfoFromTenant(param) {
        return http.confirm.tip.payload("/gateway/syinfopublish/api/pc/information/v2/saveInfoFromTenant", param)
    },
    getTenantInfo(param) {
        return http.get("/gateway/syinfopublish/api/pc/information/tenant/selectInfoById", param)
    },
    selectOpLog(param) {
        return http.get("/gateway/syinfopublish/api/pc/information/opLog/queryByPage", param)
    },
    addAction(param) {
        return http.spin.get("/gateway/syinfopublish/api/pc/information/v2/addUserRead", param)
    },
    getAction(param) {
        return http.get("/gateway/syinfopublish/api/pc/information/v2/getInfoAction", param)
    },
    getActionLog(param) {
        return http.spin.get("/gateway/syinfopublish/api/pc/information/v2/selectActionLog", param)
    },
    getPushLog(param) {
        return http.spin.get("/gateway/syinfopublish/api/pc/information/v2/selectPushLog", param)
    },
    selectContractPage(param) {
        return http.spin.get("/gateway/syinfopublish/api/pc/informationContract/selectPage", param)
    },
    selectContractList(param) {
        return http.spin.get("/gateway/syinfopublish/api/pc/informationContract/selectList", param)
    },
    updateContract(param) {
        return http.spin.payload("/gateway/syinfopublish/api/pc/informationContract/update", param)
    },
    updateContractList(param) {
        return http.spin.payload("/gateway/syinfopublish/api/pc/informationContract/updateList", param)
    },
}
export default information

