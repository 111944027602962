import http from "../../http";

const common = {
    getDict(dictType, appCode) {
        return new Promise((resolve, reject) => {
            http.get("/gateway/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: dictType,
                appCode: appCode,
            }).then(res => {
                console.log(res)
                resolve(res)
            })
        })
    },
    getBizlicense(r) {
        return  http.get("/gateway/api/lfcommon/ocr/getBizlicense", r)
    },
    getBizIdCard(r) {
        return  http.get("/gateway/api/lfcommon/ocr/getBizIdCard", r)
    },
    getBizBankCard(r) {
        return  http.get("/gateway/api/lfcommon/ocr/getBizBankCard", r)
    },
}
export default common

