/**** http.js ****/
import qs from "qs";
import request from "@/api/request";
import {message} from "@/components/Message";

const send = {
    get(url, params, tip, spin) {
        const config = {
            method: 'get',
            url: url
        }
        if (params) config.params = params
        return this.sendRequest(config, tip, spin);
    },
    post(url, params, tip, spin) {
        const config = {
            method: 'post',
            url: url
        }
        if (params) config.data = qs.stringify(params)
        return this.sendRequest(config, tip, spin);
    },
    payload(url, params, tip, spin) {
        const config = {
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        if (params) config.data = params
        return this.sendRequest(config, tip, spin)
    },
    eq(a, b) {
        return String(a) === String(b)
    },
    isSuccess(response) {
        if (response.data && response.data.code) {
            return this.eq(response.data.code, '200')
        }
        return false;
    },
    sendRequest(config, tip, spin) {
        let $spin = window.$spin;
        if ($spin && spin) {
            $spin.show();
        }
        const start = Date.now(); // 记录请求开始时间
        return new Promise((resolve, reject) => {
            let desc = '数据请求失败!'
            request(config).then((response) => {
                    if (!this.isSuccess(response)) {
                        response.data.success = false
                        if (response.data.desc && response.data.desc.length <= 200) {
                            desc = response.data.desc
                        }

                        message.error({
                            content: desc,
                            duration: 2
                        });
                    } else {
                        response.data.success = true
                        if (tip) {
                            message.success({
                                content: '操作成功',
                                duration: 1.5
                            });
                        }
                    }

                    const end = Date.now();
                    let time = end - start;

                    if (time < 1000) {
                        time = 1000
                    }

                    setTimeout(function () {
                        if ($spin && spin) {
                            $spin.hide()
                        }
                    }, time)
                    resolve(response.data);
                },
                (err) => {
                    const end = Date.now();
                    let time = end - start;

                    if (time < 1000) {
                        time = 1000
                    }

                    setTimeout(function () {
                        if ($spin && spin) {
                            $spin.hide()
                        }
                    }, time)

                    message.error({
                        content: desc,
                        duration: 2
                    });
                    reject(err)
                })
        });
    },
}
export default send