import http from "../../http";

const app = {

    selectMerchantOpen(r) {
        return  http.get("/gateway/api/merchant/merchantAppApp/v1/selectMerchantOpen",r )
    },

    selectMerchantChannelList(r) {
        return  http.spin.get("/gateway/api/merchant/merchantAppApp/v1/selectMerchantChannelList",r )
    },
    selectMerchantChannel(r) {
        return  http.get("/gateway/api/merchant/merchantAppApp/v1/selectMerchantChannel",r )
    },
    selectByAppId(r) {
        return  http.get("/gateway/api/merchant/merchantAppApp/v1/selectByAppId",r )
    },
    selectAppInfo(r) {
        return  http.get("/gateway/api/merchant/merchantAppApp/v1/selectAppInfo",r )
    },
    selectByAppChannelId(r) {
        return  http.get("/gateway/api/merchant/merchantAppApp/v1/selectByAppChannelId",r )
    },
    saveMerchantChannel(r) {
        return  http.spin.payload("/gateway/api/merchant/merchantAppApp/v1/saveMerchantChannel",r )
    },
    openApp(r) {
        return  http.spin.payload("/gateway/api/merchant/merchantAppApp/v1/openApp",r )
    },
    addChannel(r) {
        return  http.spin.payload("/gateway/api/merchant/merchantAppApp/v1/addChannel",r )
    },
    updateChannel(r) {
        return  http.spin.payload("/gateway/api/merchant/merchantAppApp/v1/updateChannel",r )
    },
    deleteMerchantChannel(r) {
        return  http.confirm.tip.post("/gateway/api/merchant/merchantAppApp/v1/deleteMerchantChannel",r )
    },
    deleteApp(r) {
        return  http.confirm.tip.post("/gateway/api/merchant/merchantAppApp/v1/delete",r )
    },
    deleteAppChannel(r) {
        return  http.confirm.tip.post("/gateway/api/merchant/merchantAppApp/v1/deleteAppChannel",r )
    },
    merchantApply(r) {
        return  http.spin.get("/gateway/api/merchant/pay/v1/merchantApply",r )
    },
}
export default app
