import http from "../../http";

const notify = {
    // 获取内部通知记录分页列表
    selectPayNotifyPage(r) {
        return  http.spin.get("/gateway/api/merchant/payNotify/v1/selectPage",r )
    },
    selectTenantByPage(r) {
        return  http.spin.get("/gateway/api/merchant/payApply/v1/selectTenantByPage",r )
    },
    selectMerchantNotifyPage(r) {
        return  http.spin.get("/gateway/api/merchant/merchantNotify/v1/selectPage",r )
    },
    selectMerchantNotifyInfo(r) {
        return  http.spin.get("/gateway/api/merchant/merchantNotify/v1/selectInfo",r )
    },
}
export default notify
