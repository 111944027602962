import http from "../../http";

const batch = {
    selectByPage(r) {
        return  http.spin.get("/gateway/api/v2/message/processor/selectByPage",r )
    },
    selectByList(r) {
        return  http.get("/gateway/api/v2/message/processor/selectByList",r )
    },
}
export default batch
