import http from "../../http";

const column = {
    selectTenantHasColumn(param){
        return http.get("/gateway/api/auth/pc/systemColumn/selectTenantHasColumn", param);
    },
    selectByParentId(param){
        return http.get("/gateway/api/auth/pc/systemColumn/selectByParentId", param);
    },
}
export default column