import http from "../../http";

const business = {
    selectByPage(r) {
        return  http.spin.get("/gateway/api/v2/message/batch/selectByPage",r )
    },
    selectInfoById(r) {
        return  http.spin.get("/gateway/api/v2/message/batch/selectInfoById",r )
    },
    statIndexTip(r) {
        return http.get("/gateway/api/v2/message/monitor/statIndexTip",r )
    },
    batchHisById(r) {
        return http.confirm.get("/gateway/api/v2/message/batch/batchHisById",r )
    },
    getBusinessInfo(r) {
        return http.get("/gateway/api/v2/message/batch/getBusinessInfo",r )
    },
    queryByList(r) {
        return http.get("/gateway/api/v2/message/businessEvent/queryByList",r )
    },

}
export default business
