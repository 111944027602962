import http from "../../http";

const trace = {

    selectTracePage(r) {
        return  http.spin.get("/gateway/api/merchant/trace/v1/selectTracePage",r )
    },
    selectTraceInfoList(r) {
        return  http.spin.get("/gateway/api/merchant/trace/v1/selectTraceInfoList",r )
    },
}
export default trace
