

import store from '@/store'
const hasPermi = (el, binding) => {
    let roleCode = window.sessionStorage.getItem('roleCodes');
    if (!roleCode) {
        el.parentNode && el.parentNode.removeChild(el)
        return
    }
    let roles = roleCode.split(',');
    const { value } = binding
    if (value && value instanceof Array) {
        if (value.length > 0) {
            const permissionRoles = value

            const hasPermission = roles.some(role => {
                return permissionRoles.includes(role)
            })

            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el)
            }
        }
    } else {
      //  el.parentNode && el.parentNode.removeChild(el)
    }
}

export default hasPermi;