import http from "../../http";

const provider = {

    getProviderList(r) {
        return http.get("/gateway/api/merchant/merchantProvider/v1/getProviderList", r)
    },

    getProviderById(r) {
        return http.spin.get("/gateway/api/merchant/merchantProvider/v1/getProviderById", r)
    },

    deleteMerchantProvider(r) {
        return http.confirm.post("/gateway/api/merchant/merchantProvider/v1/deleteMerchantProvider", r)
    },

    saveProvider(r) {
        return http.payload("/gateway/api/merchant/merchantProvider/v1/saveProvider", r)
    },
}
export default provider
