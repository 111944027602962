// vue3中创建store实例对象的方法createStore()按需引入
import { createStore } from 'vuex'

const store = createStore({
  // 声明变量
  state: {
    roleCodes: []
  },
  mutations: {
    // 定义mutations，用于修改状态state（因为state不能直接赋值修改，只能通过mutations进行修改）
    setRoleCodes(state,newVal){
      state.roleCodes = newVal

      console.log('---------------')
      console.log(newVal)
    }
  },
  actions: {
    // 定义actions，mutations的值由actions传入
    setRoleCodes(context,newVal){
      context.commit('setRoleCodes',newVal)
    }
  },
  getters: {
    // 定义一个getter
    getRoleCodes(state){
      return state.roleCodes
    }
  },
  modules: {
  }
})

export default store
