import http from "../../http";

const attendance = {
    selectDetailDatePage(param){
        return http.spin.get("/gateway/api/workflow/Attendance/selectDetailDatePage", param);
    },
    selectDevicePage(param){
        return http.spin.get("/gateway/api/workflow/Attendance/selectDevicePage", param);
    },
    delDevice(param){
        return http.confirm.post("/gateway/api/workflow/Attendance/deleteDevice", param);
    },
    saveDevice(param){
        return http.spin.payload("/gateway/api/workflow/Attendance/saveDevice", param);
    },
    selectStaffList(param){
        return http.spin.get("/gateway/apps/oldpeople/api/pc/staff/selectBaseManagerStaffList?userType=09&staffId=&oemCode=zjsm&orgCode=LFKJ", param);
    },
}
export default attendance