import http from "../../http";

const ck = {
    selectIndexTree(param){
        return http.get("/gateway/api/ck/OrgIndex/selectTree", param);
    },
    getChartNode(param){
        return http.payload("/gateway/api/ck/OrgTrend/getChartNode", param);
    },
}
export default ck