import http from "../../http";

const information = {
    selectInfoPushLogPage(param) {
        return http.spin.get("/gateway/syinfopublish/api/pc/information/v2/selectInfoPushLogPage", param)
    },
    selectPushTitleList(param) {
        return http.get("/gateway/syinfopublish/api/pc/information/v2/selectPushTitleList", param)
    },

}
export default information

