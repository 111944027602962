import http from "../../http";

const channel = {

    selectChannelByType(r) {
        return  http.get("/gateway/api/merchant/appChannel/v1/selectChannelByType",r )
    },

    flushMerchantChannelOpen(r) {
        return http.spin.get("/gateway/api/merchant/pay/v1/flushMerchantChannelOpen",r )
    },
}
export default channel
