import http from "../../http";

const orgrole = {
    selectRoleOrg(param){
        return http.spin.get("/gateway/api/auth/pc/roleOrg/selectRoleOrg", param);
    },
    change(param){
        return http.spin.payload("/gateway/api/auth/pc/roleOrg/change", param);
    },
    deleteRole(param){
        return http.spin.post("/gateway/api/auth/pc/roleOrg/deleteById", param);
    },
}
export default orgrole