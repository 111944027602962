module.exports = {
    // 生成随机数字
    randomNumber(e) {
        e = e || 9;
        var t = '0123456789',
            a = t.length,
            n = "";
        for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
        return n;
    },
    // 生成随机字符串
    randomString(e) {
        e = e || 32;
        var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
            a = t.length,
            n = "";
        for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
        return n;
    },
    // 获取url指定的值
    getUrlParam: function (data) {
        return decodeURIComponent((new RegExp('[?|&]' + data + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
    },
    processMethod(that, funcName, object) {
        let methods = that.$options.methods;
        // 解释说明一下, this需要传入目标方法, 否则,在目标方法中修改data中的值, 将不会重新渲染dom, 跟v-if结合使用的过程中需要注意
        methods[funcName](that, object);
    },

    //格式化时间日期
    // 将 Date 转化为指定格式的String
    // 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符， 
    // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
    formatDate: function (date, fmt) {
        if (!date) {
            return ''
        }
        var o = {
            "M+": date.getMonth() + 1, //月份 
            "d+": date.getDate(), //日 
            "h+": date.getHours(), //小时 
            "m+": date.getMinutes(), //分 
            "s+": date.getSeconds(), //秒 
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
            "S": date.getMilliseconds() //毫秒 
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    },


    //将数字格式化时间日期
    formatNumberToDate: function (date, fmt) {
        if (typeof (date) == 'string') {
            date = Number(date);
        }
        return this.formatDate(new Date(date), fmt);
    },
    // 分转为元
    convertCentsToYuan(cents) {
        if (!cents) {
            return "0.00";
        }
        var yuan = cents / 100; // 将分数除以100得到对应的元金额
        return yuan.toFixed(2); // 保留两位小数并返回结果
    },
    //导出excel
    exportExcel: function (tHeader, filterVal, tableData, fileName) {
        require.ensure([], () => {
            const {export_json_to_excel} = require("@/utils/Export2Excel");
            const data = function formatJson() {
                return tableData.map((v) => filterVal.map((j) => v[j]));
            }();
            export_json_to_excel(tHeader, data, fileName); //导出Excel 文件名
        })
    },

    //处理空值
    ifNULLtoDF: function (str, df_back) {
        if (str == null || str == undefined || str == "" || str == "null" || str == "undefined") {
            return df_back;
        } else {
            return str;
        }
    },

    // 数组对象根据特定属性过滤
    onlyList: function (arr, ...argus) {
        return arr.reduce((pre, cur) => {
            let condition = '';
            argus.forEach((item, index) => {
                if (index == 0) {
                    condition = 'e["' + item + '"]' + '===cur["' + item + '"]';
                } else {
                    condition += '&&' + 'e["' + item + '"]' + '===cur["' + item + '"]';
                }
            })
            if (pre.find(e => eval(condition))) {
                return pre;
            } else {
                return [...pre, cur]
            }
        }, [])
    },

    // 根据当前orgCode从iframe的userinfo中筛选出orgId
    getOrgId: function (orgCode) {
        if (!orgCode) orgCode = parent.vue.loginInfo.userinfo.orgCode;
        if (parent.vue.loginInfo.userinfo.orgUserVOS && parent.vue.loginInfo.userinfo.orgUserVOS.length > 0) {
            for (let k in parent.vue.loginInfo.userinfo.orgUserVOS) {
                if (parent.vue.loginInfo.userinfo.orgUserVOS[k].orgCode == orgCode) {
                    return parent.vue.loginInfo.userinfo.orgUserVOS[k].orgId;
                }
            }
        }
    },

    //颜色取反
    ColorReverse(OldColorValue) {
        var OldColorValue = "0x" + OldColorValue.replace(/#/g, "");
        var str = "000000" + (0xffffff - OldColorValue).toString(16);
        return "#" + str.substring(str.length - 6, str.length);
    },

    //判断颜色深浅
    ColorIsLight(color) {
        // 16进制颜色值的正则
        var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
        // 把颜色值变成小写
        color = color.toLowerCase();
        if (reg.test(color)) {
            // 如果只有三位的值，需变成六位，如：#fff => #ffffff
            if (color.length === 4) {
                var colorNew = "#";
                for (var i = 1; i < 4; i += 1) {
                    colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
                }
                color = colorNew;
            }
            // 处理六位的颜色值，转为RGB
            var colorChange = [];
            for (var i = 1; i < 7; i += 2) {
                colorChange.push(parseInt("0x" + color.slice(i, i + 2)));
            }
            return (

                0.213 * colorChange[0] +

                0.715 * colorChange[1] +

                0.072 * colorChange[2] >

                255 * 2 / 3

            );
        } else {
            return false;
        }
    },
    sequence: 1,
    //生成ID顺序名称
    createID(DF) {
        this.sequence++;
        return (DF || "LiefengView_") + this.sequence;
    },

    // 将blob转为base64,传进来的需要是一个包含type和size的blob对象
    // 直接获取返回值会获取一个到promise返回值，建议用async await获取
    blobToBase64: function (blob) {
        return new Promise((resolve, reject) => {
            // 新建一个fileReader对象，
            var reader = new FileReader()
            // readerAsDataURL获取fileReader对象的base64编码
            reader.readAsDataURL(blob)
            // 获取成功之后会执行onload函数，e.target.result的值是base64编码的值
            reader.onload = (e) => {
                resolve(e)
            }
            reader.onerror = (err) => {
                reject(err)
            }
        })
    },

    // 将base64编码转为file对象
    // 需要传入一个base64编码，以及传入文件名称
    dataURLtoFile: function (dataurl, filename, type) {
        // 首先获取到base64编码
        var arr = dataurl.split(',')
        // 将base64编码转化为字符串
        var bstr = atob(arr[1])
        // 拿到字符串的长度
        var n = bstr.length
        // 创建初始化为0，包含length个元素的无符号整数型数组
        var u8arr = new Uint8Array(n)
        // 对长度进行递减处理
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        // 最后将file对象return出去
        return new File([u8arr], filename ? filename : '默认文件名称', {type: type ? type : ''})
    },

    // 将url地址转为base64
    // 需要传入一个url网络地址，如http://xxx.png,以及type如image/png,image/jpeg
    urlToBase64: function (url, type) {
        return new Promise((resolve, reject) => {
            let image = new Image()
            image.onload = () => {
                let canvas = document.createElement('canvas')
                canvas.width = image.width
                canvas.height = image.height
                // 将图片插入画布并开始绘制2d
                canvas.getContext('2d').drawImage(image, 0, 0)
                // 获取到base64编码，
                let result = canvas.toDataURL(type)
                resolve(result)
            }
            image.setAttribute('crossOrigin', 'anonymous'); // 解决跨域
            image.crossOrigin = '*';
            image.src = url + '?v=' + Math.random();  // 解决图片URL缓存问题
            // 图片加载失败的处理
            image.onerror = (err) => {
                reject(err)
            }
        })
    },

    // 将file转blob
    // 需要传入一个file对象和type类型如："image/png"或者"image/jpeg"
    fileChangeBlob: function (file, type) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            let rs = reader.readAsArrayBuffer(file)
            let blob = null
            reader.onload = (e) => {
                if (typeof e.target.result === 'object') {
                    blob = new Blob([e.target.result], {type: type ? type : ''})
                } else {
                    blob = e.target.result
                }
                resolve(blob)
            }
            reader.onerror = (err) => {
                reject(e)
            }
        })
    },
    /**
     * fn：可以执行的回调函数，可以在里边传你想要运行的代码
     * timer: 当前页面传进来的data里边的参数，必传，类型为字符串，比如this.timer传的参数为 'timer'
     * wait: 防抖时间，点击多次之后多少时间执行，默认1000ms
     */
    debounce(fn, timer, wait = 1000) {
        var _this = this;
        if (this[timer]) clearTimeout(this[timer]);
        this[timer] = setTimeout(() => {
            fn.apply(_this);
        }, wait);
    },
    getUserInfo() {
        let userInfo = window.sessionStorage.getItem("userInfo");
        if (userInfo) {
            return JSON.parse(userInfo)
        }
        return null;
    },
    getValue(key) {
        return window.sessionStorage.getItem(key);
    },
    getJsonValue(key) {
        let item = window.sessionStorage.getItem(key);
        if (item) {
            return JSON.parse(item)
        }
        return item;
    },
    setValue(key,value) {
        if (value && value!=undefined) {
            window.sessionStorage.setItem(key,value);
        }
    },
    clearUserInfo() {
        window.sessionStorage.removeItem("lastCustGlobalId");
        window.sessionStorage.removeItem("custGlobalId");
        window.sessionStorage.removeItem("accessToken");
        window.sessionStorage.removeItem("openId");
        window.sessionStorage.removeItem("userInfo");
        window.sessionStorage.removeItem("systemList");
        window.sessionStorage.removeItem("userDetail");
    },
    logout() {
        window.sessionStorage.removeItem("custGlobalId");
        window.sessionStorage.removeItem("accessToken");
        window.sessionStorage.removeItem("openId");
        window.sessionStorage.removeItem("userInfo");
        window.sessionStorage.removeItem("systemList");
        window.sessionStorage.removeItem("userDetail");
        //window.location.href = window.location.origin
    },
    validateLoginSystem(UserInfo) {
        if (UserInfo) {
            if (UserInfo.tenantSystemId && UserInfo.orgCode && UserInfo.oemCode && UserInfo.custGlobalId) {
                return true;
            }
        }
        return false;
    },
    arrObjectRemove(arr, attr, value) {
        let index = 0;
        let flag = false;
        for (let i in arr) {
            if (arr[i][attr] === value) {
                index = i;
                flag = true
                break;
            } else {
                flag = false
            }
        }
        if (flag) {
            arr.splice(index, 1);
        }
    },
    // 格式化table表格：用于显示合并表单专用
    handleMergeRow: function (data) {
        let ret = JSON.parse(JSON.stringify(data));
        let collection = {};
        let preIndex = -1;
        for (let i in ret) {
            // item = {
            //     "name": "Jim",
            //     "age": 18,
            //     "address": "Sydney",
            //     "date": "2016-10-03"
            // }
            let item = ret[i];
            item.source = {};
            for (let key in item) {
                if (key == "source") {
                    continue;
                }
                // 记录每个key
                // collection = {
                //     "name": {}
                // }
                if (!collection[key]) {
                    collection[key] = {};
                }
                // 首项或者该项此列不等于上一项此列
                if (preIndex == -1 || ret[preIndex][key] != item[key]) {
                    // 记录每个key对应的所有值以及其起始索引和出现次数
                    // collection = {
                    //     "name": {
                    //         "Jim#0": {
                    //             "firstIndex": 0,
                    //             "count": 1
                    //         }
                    //     }
                    // }
                    collection[key][`${item[key]}#${i}`] = {
                        firstIndex: i,
                        count: 1
                    };
                    // 标注该列的起源index
                    item.source[key] = i;
                } else {
                    // 通过上一项找到起源index
                    let source = ret[preIndex].source[key];
                    item.source[key] = source;
                    // 合并该列的行数加1
                    collection[key][`${item[key]}#${source}`].count += 1;
                }
            }
            preIndex = i;
        }
        for (let key in collection) {
            // items = {
            //     "Jim#0": {
            //         "firstIndex": "0",
            //         "count": 3
            //     },
            //     "Jon#3": {
            //         "firstIndex": "3",
            //         "count": 1
            //     }
            // }
            let items = collection[key];
            for (let itemkey in items) {
                // value = {
                //     "firstIndex": "0",
                //     "count": 3
                // }
                let value = items[itemkey];
                // 根据记录的起始索引设置该项的mergeRow
                // {
                //     "name": "Jim",
                //     "age": 18,
                //     "address": "Sydney",
                //     "date": "2016-10-03",
                //     "mergeRow": {
                //         "address": 1,
                //         "age": 1,
                //         "date": 1,
                //         "name": 3
                //     }
                // }
                if (!ret[value.firstIndex].mergeRow) {
                    ret[value.firstIndex].mergeRow = {};
                }
                ret[value.firstIndex].mergeRow[key] = value.count;
            }
        }
        return ret;
    },
    // 打开layer-ui弹窗
    /**
     * 参数说明
     * type: 0（信息框，默认）, 1 (页面层) , 3（加载层），4、（tips层）， 打开路由文件需要使用 1
     * title:标题
     * area：[100%,100%] // 全屏显示
     * content：需要传入的跳转链接地址，或者是文本内容
     *
     * 详细参数参考地址：http://www.uimaker.com/layui/doc/modules/layer.html#area
     * */
    openLayerFrame: function (data, cancelCallback, successCallback, endCallback) {
        layer.open({
            area: ['100%', '100%'],
            ...data,
            cancel: () => {
                typeof (cancelCallback) === 'function' && cancelCallback()
            },
            success: () => {
                typeof (successCallback) === 'function' && successCallback()
            },
            end: () => {
                typeof (endCallback) === 'function' && endCallback()
            }
        });
    },
}